
import { useAuth0 } from "~/composables/useAuth0";

export default {
  data() {
    return {
      selectedLanguage: this.$i18n.locale,
    };
  },

  watch: {
    async selectedLanguage() {
      await this.savePreferredLangugage(this.selectedLanguage);

      this.$i18n.locale = this.selectedLanguage;
      this.$moment.locale(this.selectedLanguage);
    },
  },

  methods: {
    logout() {
      const logoutRoute = this.$router.resolve("/login");
      const logoutUrl = new URL(logoutRoute.href, window.location.origin).href;

      useAuth0().logout({
        logoutParams: { returnTo: logoutUrl },
      });
    },

    async savePreferredLangugage(locale) {
      await this.$userPreferences.setKey("preferenceNamespaceLocale", "locale", locale);
    },
  },
};
