// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_Upz9B{width:100%}.container_Upz9B,.flexContainer_RnFJJ{display:flex;flex-direction:column;height:100%}.flexContainer_RnFJJ{max-width:32rem}.buttons_9nVQ4,.pullRight_HNnSz{display:flex}.pullRight_HNnSz{justify-content:flex-end}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_Upz9B",
	"flexContainer": "flexContainer_RnFJJ",
	"buttons": "buttons_9nVQ4",
	"pullRight": "pullRight_HNnSz"
};
module.exports = ___CSS_LOADER_EXPORT___;
